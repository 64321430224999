import * as React from "react"
import '../../css/basket.css'
import {
  Button, Header, Segment, Container, Modal
} from "semantic-ui-react"
import {connect} from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";
import {navigateTo} from "../../helpers/router";
import MainTopBg from '../backgrounds/others/MainTopBg';
import Cloud from '../backgrounds/others/Cloud';

interface StateProps {
  order: any
  orderCreate: any
}

export function mapStateToProps(state: any): StateProps {
  return {
    order: state.getNewOrder,
    orderCreate: state.createOrderWithoutSurvey,
  };
}

interface DispatchProps {
  getNewOrder: typeof actions.getNewOrder,
  createOrderWithoutSurvey: typeof actions.createOrderWithoutSurvey,
}

const mapDispatchToProps = {
  getNewOrder: actions.getNewOrder,
  createOrderWithoutSurvey: actions.createOrderWithoutSurvey,
};

type Props = StateProps & DispatchProps;

interface State {
  orderCode: any,
  waiting: boolean,
  active: boolean,
  inactive: boolean,
  loading: boolean,
  showLoginRequired: boolean
  showOrderLoginRequired: boolean,
  showOrderNoPermissions: boolean
}

class CheckOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      orderCode: "",
      waiting: false,
      active: false,
      inactive: false,
      loading: true,
      showLoginRequired: false,
      showOrderLoginRequired: false,
      showOrderNoPermissions: false,
    }
  }

  componentDidMount(): void {
    this.getOrCreateNewOrder();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState): void {
    if (prevProps.order !== this.props.order) {
      let order = this.props.order;
      this.updateOrder(order);
    }
    if (prevProps.orderCreate !== this.props.orderCreate) {
      let order = this.props.orderCreate;
      this.updateOrder(order);
    }
  }

  getOrCreateNewOrder(){
    let type = localStorage.getItem('order');
    let orderCode = localStorage.getItem('orderCode');
    if(type === 'survey'){
      orderCode = localStorage.getItem('surveyOrderCode');
    }
    if(orderCode){
      let code = btoa(orderCode);
      this.setState({orderCode: orderCode}, () => this.props.getNewOrder(code));
    } else {
      this.props.createOrderWithoutSurvey();
    }
  }

  updateOrder(order){
    if(order.errors){
      if(order.data && order.data.id === 'security.no.user'){
        this.setState({showOrderLoginRequired: true, loading: false})
      } else if(order.message = "No permissions for this order"){
        this.setState({showOrderNoPermissions: true, loading: false})
      } else {
        this.setState({loading: false});
      }
    } else if(order.data){
      let activeOrder = order.data;
      if(activeOrder.code !== this.state.orderCode){
        localStorage.setItem('orderCode', activeOrder.code);
        localStorage.setItem('order', 'normal');
      }
      if (activeOrder.status === 'new' || activeOrder.status === 'created') {
        let type = localStorage.getItem('order') ?? 'normal';
        navigateTo(`/order?type=${type}`)
      }
      if (activeOrder.status === 'waiting_payment') {
        this.setState({waiting: true, loading: false})
      }
      if (activeOrder.status === 'active') {
        this.setState({active: true, loading: false})
      }
      if (activeOrder.status === 'inactive') {
        this.setState({inactive: true, loading: false})
      }
    }
  }

  handleCallCreateNewOrder(){
    this.setState({loading: true}, () => this.props.createOrderWithoutSurvey())
  }

  modalOrderLoginRequired(){
    return (
      <Modal
        open={this.state.showOrderLoginRequired}
        dimmer="inverted"
        centered={true}
      >
        <Modal.Header>Znaleziono aktywne zamówienie</Modal.Header>
        <Modal.Content>
          <Header as="h4">Aby kontynuować zamówienie należy się najpierw zalogować na konto przypisane do tego zamówienia</Header>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => navigateTo('/login')}>
            Zaloguj
          </Button>
          <Button secondary onClick={() => this.setState({showOrderLoginRequired: false}, () => this.props.createOrderWithoutSurvey())}>
            Utwórz nowe zamówienie
          </Button>
      </Modal.Actions>
      </Modal>
    )
  }

  modalOrderNoPermissions(){
    return (
      <Modal
        open={this.state.showOrderNoPermissions}
        dimmer="inverted"
        centered={true}
      >
        <Modal.Header>Znaleziono zamówienie, które należy do innego użytkownika</Modal.Header>
        <Modal.Content>
          <Header as="h4">Aby kontynuować zamówienie należy się najpierw zalogować na konto przypisane do tego zamówienia, 
            bądź też utworzyć nowe zamówienie lub wypełnić ankietę.</Header>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => navigateTo('/login')}>
            Zaloguj
          </Button>
          <Button secondary onClick={() => this.setState({showOrderNoPermissions: false}, () => this.props.createOrderWithoutSurvey())}>
            Utwórz nowe zamówienie
          </Button>
          <Button secondary onClick={() => navigateTo('/survey')}>
            Wypełnij ankietę
          </Button>
      </Modal.Actions>
      </Modal>
    )
  }

  otherStatusesContent(){
    if (this.state.waiting) {
      return (
        <Container textAlign='center'>
          <Header as='h1'>Oczekujemy na płatność</Header>
          <Button onClick={() => navigateTo('/')}>Przejdź do strony głównej</Button>
          <Button secondary onClick={() => navigateTo('/orderhistory')}>Przejdź do historii zamówień</Button>
          <Button secondary onClick={() => this.setState({loading: true},
              () => this.props.createOrderWithoutSurvey())}>Utwórz nowe zamówienie</Button>
        </Container>
      )
    } else if (this.state.inactive) {
      return (
        <Container textAlign='center'>
          <Header as='h1'>Zlecenie zostało anulowane i nie jest już aktywne</Header>
          <Button onClick={() => navigateTo('/survey')}>Przejdź do ankiety</Button>
          <Button secondary onClick={() => navigateTo('/orderhistory')}>Przejdź do historii zamówień</Button>
          <Button secondary onClick={() => this.setState({loading: true},
              () => this.props.createOrderWithoutSurvey())}>Utwórz nowe zamówienie</Button>
        </Container>
      )
    } else if (this.state.active) {
      return (
        <Container textAlign='center'>
          <Segment basic>
            <Header as='h1'>Twoje zamówienie jest aktywne.</Header>
            <Header as='h3'>Możesz przejść do historii zamówień lub utworzyć nowe zamówienie.</Header>
          </Segment>
          <Button onClick={() => navigateTo('/orderhistory')} primary>Historia zamówień</Button>
          <Button secondary onClick={() => this.setState({loading: true, active: false},
            () => this.props.createOrderWithoutSurvey())}>Utwórz nowe zamówienie</Button>
        </Container>
      )
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Segment style={{width: "100%", height: "100vh"}} loading={true}/>
      )
    } else if(this.state.waiting || this.state.inactive || this.state.active){
      return (
        <MainTopBg>
          <Segment basic style={{minHeight: "calc(100vh - 10px)", paddingTop: "150px", marginTop: 0}}>
            {this.otherStatusesContent()}
          </Segment>
        </MainTopBg>
      )
    } else {
      return (
        <>
          {this.modalOrderLoginRequired()}
          {this.modalOrderNoPermissions()}
          <Cloud>
            <Segment basic id="cloud-panel" style={{height: "100vh", display: "flex", alignItems: "center"}}>
              <Container textAlign="center">
                <Header id='cloud-subheader'>Utwórz nowe zamówienie</Header>
                <Button primary onClick={() => this.props.createOrderWithoutSurvey()}>Utwórz zamówienie</Button>
              </Container>
            </Segment>
          </Cloud>
        </>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOrder);