import * as React from "react"
import Layout from "../templates/layout";
import FullApp from "../FullApp";
import CheckOrder from "../components/pages/checkOrder";
import { Helmet } from 'react-helmet';

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <CheckOrder location={props.location}/>
    </Layout>
  </FullApp>
);